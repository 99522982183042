import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  AfterViewInit,
  Injectable,
  PLATFORM_ID,
  Inject,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { ServerService } from "src/app/service/server.service";
import { CommonService } from "src/app/service/common.service";
import * as moment from "moment";

@Component({
  selector: "app-address-modal",
  templateUrl: "./address-modal.component.html",
  styleUrls: ["./address-modal.component.scss"],
})
export class AddressModalComponent implements OnInit, OnDestroy {
  // @Input() customerId: any;
  // @Input() id: any;
  // @ViewChild('addressModal') AddressModal: TemplateRef<any>;

  @Input() data;

  public closeResult: string;
  public modalOpen: boolean = false;
  public addressForm: UntypedFormGroup;
  public baseUrl = "customerAddress";
  public states: any[];
  public selectedCities: any[] = [];
  public model: any = {
    // id: null,
    customer_id: null,
    type: null,
    name: null,
    phone: null,
    state: null,
    city: null,
    address: null,
    zip: null,
  };
  public userData = JSON.parse(localStorage.getItem("UserData"));

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private ref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private serverService: ServerService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    if (this.data) {
      this.model = this.data;
    } else {
      this.model.phone = this.userData.phone;
    }

    this.formBuild();
    this.getAllStatesCities();
  }

  getAllStatesCities() {
    this.serverService.getAll("address").subscribe((res) => {
      this.states = res.data;
      if (this.model?.state) {
        this.filterCities(this.model?.state);
      }
    });
  }

  filterCities(state) {
    let selectedState: any = this.states.filter((i) => i.state_id == state);
    this.selectedCities = selectedState[0].cities;
  }

  formBuild() {
    this.addressForm = this.formBuilder.group({
      type: [
        this.model?.type,
        {
          validators: [Validators.required],
          asyncValidators: [this.hasDuplicate.bind(this)],
        },
      ],
      name: [this.model?.name, [Validators.required]],
      phone: [
        this.model?.phone,
        [Validators.required, Validators.minLength(10)],
      ],
      state: [this.model?.state, [Validators.required]],
      city: [this.model?.city, [Validators.required]],
      address: [this.model?.address, [Validators.required]],
      zip: [this.model?.zip, [Validators.required, Validators.minLength(6)]],
    });
  }

  showSave() {
    return this.addressForm?.status === "VALID";
  }

  ngOnDestroy() {}

  hasDuplicate(): Promise<any> {
    return new Promise((resolve, reject) => {
      const formControl: AbstractControl = this.addressForm?.get("type");
      if (
        formControl &&
        (formControl.errors === null || formControl.errors.duplicate)
      ) {
        const value: string | null = this.addressForm.get("type").value;
        if (formControl.hasError("duplicate")) {
          delete formControl.errors.duplicate;
        }

        this.serverService
          .hasDuplicate(
            "customerAddress",
            this.model?.id || 0,
            value,
            this.userData.id
          )
          .subscribe(
            (res: any) => {
              if (res.data && res.data.hasDuplicate) {
                resolve({ duplicate: res.data.hasDuplicate });
              }
              resolve(null);
            },
            (error) => {
              reject(true);
            }
          );
      } else {
        resolve(null);
      }
    });
  }

  async save() {
    this.model.type = this.addressForm.get("type").value;
    this.model.customer_id = this.userData.id;
    this.model.name = this.addressForm.get("name").value;
    this.model.phone = this.addressForm.get("phone").value;
    this.model.state = this.addressForm.get("state").value;
    this.model.city = this.addressForm.get("city").value;
    this.model.address = this.addressForm.get("address").value;
    this.model.zip = this.addressForm.get("zip").value;

    if (this.model.id) {
      await CommonService.edit(this);
	  this.activeModal.close();
    } else {
      await CommonService.save(this);
      this.activeModal.close();
    }
  }
}
