<div *ngIf="!loader">
    <div class="img-wrapper">
        <div class="lable-block">
            <span class="lable3" *ngIf="product.nm">new</span>
            <span class="lable4 on-sale" *ngIf="product.nm">on sale</span>
        </div>
        <div class="front">
            <a [routerLink]="['/shop/product/'+product?.id+'/', getUrl(product?.nm)]">
                <img [lazyLoad]="product?.view?.url" class="img-fluid lazy-loading" alt="{{ getUrl(product.nm) }}" />
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <a [routerLink]="['/shop/product/'+product?.id+'/', getUrl(product?.nm)]">
                <img [src]="product?.view?.url" class="img-fluid lazy-loading" alt="{{ getUrl(product.nm) }}">
            </a>
        </div>
        <ul class="product-thumb-list" *ngIf="thumbnail">
            <!-- <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li> -->
        </ul>
        <div class="cart-info cart-wrap">
            <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </a> -->
            <a href="javascript:void(0)" title="Add to cart" *ngIf="userData?.id" (click)="addToCart($event, product)">
                <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to Wishlist" *ngIf="userData?.id && !product?.wishlist" (click)="addToWishlist($event, product)">
                <i class="ti-heart" aria-hidden="true"></i>
            </a>

            <a class="red-heart" href="javascript:void(0)" title="Remove from Wishlist" *ngIf="userData?.id && product?.wishlist" (click)="removeWishList(product)">
                <img src="assets/images/red-heart.png">
            </a>
            <!-- <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a> -->
            <!--<a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>-->
        </div>
    </div>
    <div class="product-detail">
        <div style="padding-left:2px">
            <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
            <a [routerLink]="['/shop/product/'+product?.id+'/', getUrl(product?.nm)]">
                <h6 class="product-name">{{ product?.nm | titlecase }}</h6>
            </a>
            <p>{{ product?.nm }}</p>
            <h4>
                {{ product?.view?.price?.sp | currency:currency?.currency:'symbol'}}
                <!-- {{ product?.mrp }}-->
                <!--<del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>-->
            </h4>
            <h6 class="mrp-content">
                {{ (product?.view?.price?.mrp||0) | currency:currency?.currency:'symbol'}}
            </h6>
            <ul class="color-variant" *ngIf="product?.view?.color?.hasColor">
                <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                </li>
            </ul>
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<!-- <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->