<!--footer section -->
<footer [class]="class">
    <!-- News letter -->
    <!-- <div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Heksh By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form">
							<div class="form-group mx-sm-3">
                <input type="text" [formControl]="subscribeForm.controls['em']" class="form-control" id="email" placeholder="Enter your email" required="">
                <small class="text-theme" *ngIf="subscribeForm.controls['em'].hasError('required') && showErrors">*Email is required</small>
                <small class="text-theme" *ngIf="subscribeForm.controls['em'].hasError('email') && showErrors">*Please enter a valid email address</small>
							</div>
							<button (click)="subscribe()" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-title footer-mobile-title">
                        <h4>about</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="footer-logo">
                            <img [src]="themeLogo" class="footerLogo" alt="logo">
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                        <div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100069320844666" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://x.com/HekshOfficial" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/heksh_the_atelier/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col offset-xl-1">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>Categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a [routerLink]="['/shop/men']">Men</a></li>
                                <li><a [routerLink]="['/shop/women']">Women</a></li>
                                <li><a [routerLink]="['/shop/kids']">Kids</a></li>
                                <li><a [routerLink]="['/shop/bags']">Bags</a></li>
                                <li><a [routerLink]="['/shop/footwear']">Footwear</a></li>
                                <li><a [routerLink]="['/shop/accessories']">Accessories</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>POLICIES</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a [routerLink]="['/pages/terms']">Terms of Service</a></li>
                                <li><a [routerLink]="['/pages/privacy']">Privacy Policy</a></li>
                                <li><a [routerLink]="['/pages/shipping']">shipping and Return Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>INFORMATION</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a [routerLink]="['/pages/aboutus']">About</a></li>
                                <li><a href="https://www.shiprocket.in/shipment-tracking/" target="_blank">Track Your Order</a></li>
                                <li><a [routerLink]="['/pages/contact']">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Heksh.in | Designed and Developed By <a href="https://www.pepytechnologies.com/" target="_blank">Pepy Technologies</a></p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->