<!-- <section class="pb-0">
    <div class="container">
      <div class="row partition2">
        <div class="col-md-6 mb-3" *ngFor="let category of dynamicContent">
          <a [routerLink]="['/shop/'+getUrl(category.nm)]">
            <div class="collection-banner p-right text-center">
              <img [src]="ParseImage(category.zf)" class="img-fluid" alt="collection-banner">
              <div class="contain-banner" style="background: rgba(0,0,0,0.5);">
                <div>
                    <h4 class="text-theme">{{category.dsc}}</h4>
                    <h2 class="text-white">{{category.nm}}</h2>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section> -->

<!-- <section class="pt-20 pb-50">
    <div class="container home-categories-bg">
      <div class="medi-title">
        <h3 class="medi_custom_heading">
          <a href="">Top Categories</a> 
        </h3>
        </div>
      <div class="row ">
        <div class="col-6 col-md-3 col-lg-2 col-sm-6 cat-mb-20" *ngFor="let category of dynamicContent">
          <div class="categorie-card wow animate__fadeInUp" wow-offset="0" wow-duration="500ms" wow-delay="100ms"
              style="visibility: visible; animation-duration: 500ms; animation-delay: 100ms; animation-name: fadeInUp;">
            <div class="categorie-card-img">
              <a [routerLink]="['/shop', category.slug]"><img [src]="ParseImage(category.zf)" class="img" alt="categories" style="width: 100%; object-fit: contain;"></a>
            </div>
            <div class="categorie-card-content">
              <a [routerLink]="['/shop', category.slug]">
                <span>{{category.nm}}</span>
               <a class="categorie-button" [routerLink]="['/shop', category.slug]">Shop</a>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>-->




<!--<section class="section-b-space home-categories-bg no-b-space">
    <div class="container">
        <div class="medi-title">
            <h3 class="medi_custom_heading">
                <a href="">Best Selling Products</a>
            </h3>
        </div>
        <div class="row margin-default ratio_square">
            <div class="col-xl-2 col-sm-3 col-4" *ngFor="let category of dynamicContent">
                <a href="#">
                    <div class="img-category">
                       
                        <div class="img-sec bg-size"  [ngStyle]="{'background-image': 'url(ParseImage(category.zf))','display':'block','background-size': 'cover','background-position': 'center center'}"></div>
                        <h4>{{category.nm}}</h4>
                    </div>
                </a>
            </div>
            
            
        </div>
    </div>
</section>-->

<div class="container">
    <div class="title1 section-t-space">
        <h2 class="text-theme">Popular Categories</h2>
    </div>
</div>
<section class="section-b-space home-categories-bg no-b-space">
    <div class="container">

        <div class="row margin-default ratio_square home-categ">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-4" *ngFor="let category of dynamicContent">
                <a [routerLink]="['/shop', category.slug]">
                    <div class="img-category">
                        <div class="img-sec">
                            <img [src]="ParseImage(category.zf)" class="img-fluid bg-img" alt="">
                        </div>
                        <h4>{{category.nm}}</h4>
                    </div>
                </a>
            </div>

        </div>
    </div>
</section>

<div class="section no-pt-section collection-box collection-banner-grid" style="display:none;">
    <div class="container bg-gradient-2">
        <div class="medi-title">
            <h3 class="medi_custom_heading">
                <a href="">Best Selling Products</a>
            </h3>
        </div>
        <div class="row">
            <div class="col-6 col-sm-6 col-md-4 col-lg-3" *ngFor="let featured of featuredProducts">
                <div class="collection-item">
                    <a [routerLink]="['/shop/product/'+featured?.id+'/', getUrl(featured?.nm)]" class="collection-grid-link">
                        <div class="img">
                            <img [src]="ParseImage(featured.zf)" alt="" class="" />
                        </div>
                        <div class="details">
                            <h3 class="collection-item-title">{{featured.nm}}</h3>

                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section imgBanners style2 no-pb-section mt-5">
    <div class="container">
        <div class="medi-title">
            <h3 class="medi_custom_heading">
                <a href="">Best Offers</a>
            </h3>
        </div>
        <div class="collection-banners">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 img-banner-item">
                    <div class="imgBanner-grid-item">
                        <div class="inner btmleft item-1pro">
                            <a [routerLink]="['/shop', this.banner1[0] && this.banner1[0]?.bu]">
                                <span class="img">
                                <img class="" [src] ="ParseImage(this.banner1[0] && this.banner1[0].zf)" alt="" title=" " >
                            </span>

                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 img-banner-item">
                    <div class="imgBanner-grid-item">
                        <div class="inner btmleft item-2pro">
                            <a [routerLink]="['/shop', this.banner2[0] && this.banner2[0]?.bu]">
                                <span class="img">
                                <img class="" [src] ="ParseImage(this.banner2[0] && this.banner2[0].zf)" alt="" title=" " >
                            </span>

                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 img-banner-item" style="display: none;">
                    <div class="row">

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 img-banner-item">
                            <div class="imgBanner-grid-item">
                                <div class="inner btmleft item-2pro">
                                    <a [routerLink]="['/shop', this.banner2[0] && this.banner2[0].bu]">
                                        <span class="img">
                                        <img class=""  [src] = "ParseImage(this.banner2[0] && this.banner2[0].zf)" alt="" title=" " >
                                    </span>

                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 img-banner-item last">
                            <div class="imgBanner-grid-item">
                                <div class="inner btmleft item-3pro">
                                    <a [routerLink]="['/shop', this.banner3[0] && this.banner3[0].bu]">
                                        <span class="img">
                                        <img class=""  [src] = "ParseImage(this.banner3[0] && this.banner3[0].zf)" alt="" title=" ">
                                    </span>

                                    </a>
                                </div>
                            </div>
                            <div class="imgBanner-grid-item">
                                <div class="inner btmleft item-4pro">
                                    <a [routerLink]="['/shop', this.banner4[0] && this.banner4[0].bu]">
                                        <span class="img">
                                        <img class="" [src] = "ParseImage(this.banner4[0] && this.banner4[0].zf)" alt="" title=" ">
                                    </span>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>