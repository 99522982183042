<div class="modal-content">
    <div class="modal-body modal13 reviewmodal">
        <div class="container-fluid p-0">
            <form [formGroup]="addressForm" novalidate>
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(0)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="age-content">
                                <h2> Add Address</h2>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="required">Address Type</label>
                                        <input type="text" class="form-control" placeholder="Enter Address Type" name="type" formControlName="type" [(ngModel)]="model.type" [ngClass]="{'input-error':addressForm.get('type').invalid && addressForm.get('type').touched}">
                                        <div class="error-message" *ngIf="addressForm.get('type').invalid && addressForm.get('type').touched">
                                            <div *ngIf="addressForm.get('type').errors?.required">
                                                Required.
                                            </div>
                                            <div *ngIf="addressForm.get('type').errors?.minlength">
                                                Minimum 2 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('type').errors?.maxlength">
                                                Maximum 256 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('type').errors?.duplicate">
                                                Duplicate name.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="required">Name</label>
                                        <input type="text" class="form-control" placeholder="Enter Your name" name="name" formControlName="name" [(ngModel)]="model.name" [ngClass]="{'input-error':addressForm.get('name').invalid && addressForm.get('name').touched}">
                                        <div class="error-message" *ngIf="addressForm.get('name').invalid && addressForm.get('name').touched">
                                            <div *ngIf="addressForm.get('name').errors?.required">
                                                Required.
                                            </div>
                                            <div *ngIf="addressForm.get('name').errors?.minlength">
                                                Minimum 2 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('name').errors?.maxlength">
                                                Maximum 256 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('name').errors?.duplicate">
                                                Duplicate name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="required">Phone</label>
                                        <input type="text" class="form-control" appOnlyNumbers placeholder="Phone" required name="phone" formControlName="phone" [(ngModel)]="model.phone" maxlength="10" [ngClass]="{'input-error':addressForm.get('phone').invalid && addressForm.get('phone').touched}">
                                        <div class="error-message" *ngIf="addressForm.get('phone').invalid && addressForm.get('phone').touched">
                                            <div *ngIf="addressForm.get('phone').errors?.required">
                                                Required.
                                            </div>
                                            <div *ngIf="addressForm.get('phone').errors?.maxlength">
                                                Maximum 256 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('phone').errors?.duplicate">
                                                Duplicate name.
                                            </div>
                                            <div *ngIf="addressForm.get('phone').errors?.pattern" class="text text-danger">
                                                Phone No is must be number.
                                            </div>
                                            <div *ngIf="addressForm.get('phone').errors?.minlength" class="text text-danger">Invalid phone number.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="required">State</label>
                                        <select class="form-control" name="state" formControlName="state" [(ngModel)]="model.state" (change)="filterCities($event.target.value)" [ngClass]="{'input-error':addressForm.get('state').invalid && addressForm.get('state').touched}">
                                        <option value="0" selected disabled>--Select State--</option>
                                        <option *ngFor="let state of states" [value]="state.state_id">{{state.state_name}}</option>
                                    </select>
                                        <div class="error-message" *ngIf="addressForm.get('state').invalid && addressForm.get('state').touched">
                                            <div *ngIf="addressForm.get('state').errors?.required">
                                                Required.
                                            </div>
                                            <div *ngIf="addressForm.get('state').errors?.minlength">
                                                Minimum 2 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('state').errors?.maxlength">
                                                Maximum 256 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('state').errors?.duplicate">
                                                Duplicate name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-md-6">
                                        <label class="required">City</label>
                                        <select name="city" class="form-control" formControlName="city" [(ngModel)]="model.city" [ngClass]="{'input-error':addressForm.get('city').invalid && addressForm.get('city').touched}">
                                        <option value="0" selected disabled>--Select City--</option>
                                        <option *ngFor="let city of selectedCities" [value]="city.id">{{city.city}}</option>
                                    </select>
                                        <div class="error-message" *ngIf="addressForm.get('city').invalid && addressForm.get('city').touched">
                                            <div *ngIf="addressForm.get('city').errors?.required">
                                                Required.
                                            </div>
                                            <div *ngIf="addressForm.get('city').errors?.minlength">
                                                Minimum 2 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('city').errors?.maxlength">
                                                Maximum 256 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('city').errors?.duplicate">
                                                Duplicate name.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="required">Zipcode</label>
                                        <input type="text" class="form-control" appOnlyNumbers maxlength="6" placeholder="Zipcode" required name="zip" formControlName="zip" [(ngModel)]="model.zip" [ngClass]="{'input-error':addressForm.get('zip').invalid && addressForm.get('zip').touched}">
                                        <div class="error-message" *ngIf="addressForm.get('zip').invalid && addressForm.get('zip').touched">
                                            <div *ngIf="addressForm.get('zip').errors?.required">
                                                Required.
                                            </div>
                                            <div *ngIf="addressForm.get('zip').errors?.minlength">
                                                Invalid Zipcode
                                            </div>
                                            <div *ngIf="addressForm.get('zip').errors?.maxlength">
                                                Invalid Zipcode
                                            </div>
                                            <div *ngIf="addressForm.get('zip').errors?.duplicate">
                                                Duplicate name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="required">Address</label>
                                        <textarea name="address" class="form-control" placeholder="" autocomplete="off" formControlName="address" [(ngModel)]="model.address" [ngClass]="{'input-error':addressForm.get('address').invalid && addressForm.get('address').touched}"></textarea>
                                        <div class="error-message" *ngIf="addressForm.get('address').invalid && addressForm.get('address').touched">
                                            <div *ngIf="addressForm.get('address').errors?.required">
                                                Required.
                                            </div>
                                            <div *ngIf="addressForm.get('address').errors?.minlength">
                                                Minimum 2 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('address').errors?.maxlength">
                                                Maximum 256 characters.
                                            </div>
                                            <div *ngIf="addressForm.get('address').errors?.duplicate">
                                                Duplicate name.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-solid" type="submit" (click)="save()" [disabled]="!showSave()">Save Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>